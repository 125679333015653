@import '../../Colors.scss';

.header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0px;
        max-width: 420px;
        font-size: 5rem;
    }
    &.small {
        position: fixed;
        top: 0px;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        .text {
            max-width: 84px;
            font-size: 20px;
            padding: 20px 5px;
        }
        .small-text {
            display: none;
        }

        .logo {
            width: 30px;
            height: 30px;
            padding: 10px;
            display: flex;
        }
    }

    .small-text {
        display: block;
        font-size: 12px;
        text-align: center;
    }

    .logo {
        display: none;
    }
}

@media(max-width: 600px) {
    .header {
        &.small {
            .text {
                display: none;
            }
        }
    }
}