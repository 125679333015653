@import '../../Colors.scss';

.footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
    .text {
        text-align: center;
        color: $text-color;
        background-color: $background-color;
        padding: 10px;
    }
    .icons-bar {
        display: flex;
        justify-content: center;
        align-content: space-between;
        .contact-icon {
            background-color: #0077B5;
            border-radius: 20%;
            padding: 5px 7px;
            margin: 5px;
            cursor: pointer;
            img {
                width: 25px;
                height: 25px;
            }
            
        }
    }
}

@media(max-width: 400px) {
    .footer {
        .icons-bar {
            background-color: $background-color;
        }
    }
}