@import '../../Colors.scss';

.page-content {
    height: 800px;
    padding-top: 260px;
    color: $text-color;
    background-color: $background-color;
    .section {
        .title {
            text-align: center;
            font-size: 30px;
        }
        .about-content {
            text-align: center;
        }
    }
    .space {
        height: 100px;
    }
   
}

@media(min-height: 1200px) {
    .page-content {
        height: 1200px;
    }
}