@import '../../../Colors.scss';

.section {
    padding: 20px;
    text-align: center;
    background-color: $background-color;

    .title {
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
    }

    .content-wrapper {
        display: flex;
        justify-content: center;
        transform: scale(0.94);
        animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
        .content {
            text-align: left;
            width: 40%;
            padding: 5px 10px;
        }
    }

    .fade-in-text {
        span {
           opacity: 0;
           filter: blur(4px);
           $indexes: 1,3,5,7,9;
           @each $index in $indexes {
            &:nth-child(#{$index}) {
                animation: fade-in 0.8s #{$index * 0.8}s forwards cubic-bezier(0.11, 0, 0.5, 0);
             }
           }
        }       
    }

    .tags {
        .tag {
            opacity: 0;
            filter: blur(4px);
            $indexes: 1,2,3;
            @each $index in $indexes {
                &:nth-child(#{$index}) {
                    animation: fade-in 0.8s #{$index * 0.8}s forwards cubic-bezier(0.11, 0, 0.5, 0);
                }
            }
        }
    }

    @keyframes scale {
        100% {
            transform: scale(1);
        }
    }

    @keyframes fade-in {
        100% {
            opacity: 1;
            filter: blur(0);
        }
    }
}

@media(max-width: 600px) {
    .section {
        .content-wrapper {
            .content {
                width: 80%;
            }
        }
    }
}