@import './Colors.scss';
.root {
  background-color: $background-color;

  .main {
    width: 100%;
    height: 100%;
      .typing-text {
      overflow: hidden;
      white-space: nowrap;
      animation: typing 3s steps(7, end) forwards,
                  blink 1s infinite;
      border-right: .15em solid orange;
    }
  
    @keyframes typing {
      from {
        width: 0
      }
  
      to {
        width: 100%
      }
    }
  
    @keyframes blink {
      from {
        border-color: transparent
      }
  
      to {
        border-color: orange;
      }
    }

  }
}

.parallax-background {
  background: url('../public/softcompany_layout_clean.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}