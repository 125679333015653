.scrollme {
    position: absolute;
    bottom: 120px;
    color: #fff;
    width: 100%;
    animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-direction: alternate;
    animation-iteration-count: infinite;
    .scrollme-inner {
        position:relative;
        display: flex;
        justify-content: center;
       .scroller {
            border-left: 5px solid #fff;
            border-bottom: 5px solid #fff;
            transform: rotate(-45deg);
            width: 20px;
            height: 20px;
            cursor: pointer;
       }
    }
    @keyframes bounce {
        0% {
            bottom: 120px;
        }

        100% {
            bottom: 100px;
        }
    }
}